import { UsersSchemaWithId } from "components/users/users.schema"
import compact from "lodash/compact"

import http, { isHttpError } from "./http"

// any is needed since this function can take anything
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNestedPropertyValueOrNull = (p: string, o: any): any => p.split(".").reduce((xs: any, x: any) => (xs && xs[x] ? xs[x] : null), o)

/**
 * @param error the object to check
 * @returns true if error is a PatchFailure
 */
export function isPatchError(error: unknown): error is PatchFailure {
    return getNestedPropertyValueOrNull("error", error)
}

export class Sessions {
    public async getSessionUserId(): Promise<number> {
        const sessionResponse = await http.get<UsersSchemaWithId[]>(process.env.FLYPAPER_API_URL + "/auth/session")
        if (isHttpError(sessionResponse) || sessionResponse.length === 0) return 0

        return sessionResponse[0].id
    }
    public async getSessionData(): Promise<UsersSchemaWithId[]> {
        const sessionResponse = await http.get<UsersSchemaWithId[]>(process.env.FLYPAPER_API_URL + "/auth/session")
        if (isHttpError(sessionResponse) || sessionResponse.length === 0) return []

        return sessionResponse
    }
}

/**
 * @param text the text to change
 * @returns the text with the first char uppercased
 */
export function ucFirst(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

/**
 * @param input The string or null to check
 * @returns true if input is null or if it is a string of whitespace
 */
export function isNullOrWhitespace(input: string | number | null): boolean {
    if (typeof input === "undefined" || input === null) return true

    if (typeof input === "string") return input.replace(/\s/g, "").length < 1

    return false
}

/**
 * @param object The object to check
 * @returns true if object is an instance of sse
 */
export function instanceOfSSE(object: unknown): object is SSEData {
    if (typeof object === "object" && object !== null) return "action" in object
    return false
}

/**
 * @param func the function to call at the end of the debounce
 * @param delay the debounce delay
 * @param statusCallback the callback function to call when saving is happening or has happened
 * @param lockFunction lock function
 * @returns the debounced function to use
 */
export function queueAndDebounceSaves(
    func: (props: Array<string | undefined>, arrayMode?: PatchArrayMode, projectId?: number) => Promise<boolean>,
    delay: number,
    statusCallback?: (status: "saving" | "saved" | "error", message: string) => void,
    lockFunction?: (props?: string[]) => void
): (props: string[] | string | undefined, arrayMode?: PatchArrayMode) => void {
    let propsToSave: Array<string | undefined> = []
    let waitingToSavePatchArrayMode: PatchArrayMode | undefined = undefined
    let isWaiting = false

    // any is required here because setTimeout on mobile has a different type than on web, changing this to number will break mobile.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeout: any

    const executeSave = async function (now?: boolean) {
        if (statusCallback) statusCallback("saving", "")
        isWaiting = true

        const save = async () => {
            const saved = await func(propsToSave, waitingToSavePatchArrayMode)
            if (saved) {
                if (lockFunction) {
                    const nonUndefinedProps = compact(propsToSave)
                    // NOTE lock the entire record if any of the propsToSave are undefined
                    if (nonUndefinedProps.length !== propsToSave.length) lockFunction(undefined)
                    else lockFunction(nonUndefinedProps)
                }

                if (statusCallback) statusCallback("saved", "")
                propsToSave = []
            } else {
                if (statusCallback) statusCallback("error", "")
            }

            isWaiting = false
        }

        if (now) {
            clearTimeout(timeout)
            await save()
        } else {
            timeout = setTimeout(save, delay)
        }
    }

    return async function (props: string[] | string | undefined, patchArrayMode?: PatchArrayMode) {
        if (patchArrayMode !== waitingToSavePatchArrayMode && isWaiting) await executeSave(true)

        waitingToSavePatchArrayMode = patchArrayMode

        if (props === undefined) {
            propsToSave.push(props)
            if (lockFunction) lockFunction(props)
        } else if (Array.isArray(props)) {
            propsToSave.push(...props)
            if (lockFunction) lockFunction(props)
        } else {
            propsToSave.push(props)
            if (lockFunction) lockFunction([props])
        }

        if (isWaiting) clearTimeout(timeout)

        executeSave()
    }
}

/**
 * Hex to RGB
 *
 * @param hex the hex color string
 * @returns An object representing the rgb color
 */
export function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m: string, r: string, g: string, b: string) {
        return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : null
}

/**
 * srgb to linear rgb
 *
 * @param c srgb color number
 * @returns  the linear rgb representation of the number
 */
export function srgbToRgb(c: number): number {
    c = c / 255.0
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
}

/**
 * Determines if text color should be white or black based on background color
 *
 * @param hexBackgroundColor The hex background color the text will be on
 * @returns  "white" or "black"
 */
export function whiteOrBlackText(hexBackgroundColor: string): "white" | "black" {
    const color = hexToRgb(hexBackgroundColor)
    if (color === null) return "black"

    const L = 0.2126 * srgbToRgb(color.r) + 0.7152 * srgbToRgb(color.g) + 0.0722 * srgbToRgb(color.b)
    if (L > 0.179) return "black"

    return "white"
}

/**
 * Convert image to base64
 *
 * @param url url
 * @param sessionId user session id
 * @returns String base64
 */
export async function toDataUrl(url: string, sessionId?: string): Promise<string> {
    let base64ImageLogo

    if (sessionId) {
        base64ImageLogo = await http.get<string>(
            url,
            {
                headers: {
                    Authorization: "Bearer " + sessionId
                }
            },
            true
        )

        if (isHttpError(base64ImageLogo)) return ""
    } else {
        base64ImageLogo = await http.get<string>(url, undefined, true)

        if (isHttpError(base64ImageLogo)) return ""
    }

    return base64ImageLogo
}
