/**
 * Base Vue Component
 *
 * @description The parent component from which all other components in Protest are extended from.
 * @augments Vue
 * @class
 * @global
 */
import { whiteOrBlackText } from "common/client.utils"
import { CompaniesSchemaWithId } from "components/companies/companies.schema"
import { HubSchemaWithId } from "components/hub/hub.schema"
import { AreasSchemaWithId } from "components/project/areas/areas.schema"
import { ProjectSchemaWithId } from "components/project/project.schema"
import { ShiftsSchemaWithId } from "components/project/shifts/shifts.schema"
import { store } from "components/protest.store"
import { TodosSchemaWithId } from "components/todos/todos.schema"
import { UsersSchemaWithId } from "components/users/users.schema"
import Snacks from "components/widgets/snacks/snacks.component.vue"
import SnackEmitter from "components/widgets/snacks/snacks.emitter"
import Vue from "vue"
import { Component } from "vue-property-decorator"
import { Store } from "vuex"
import { Getter, State } from "vuex-class"

@Component({
    components: {
        Snacks
    },
    store,
    filters: {
        truncate: function (text: string, length: number, suffix: unknown) {
            if (text.length > length) return text.substring(0, length) + suffix
            else return text
        }
    }
})
export default class BaseVueComponent extends Vue {
    public $store!: Store<typeof store["state"]>
    // @ts-ignore to remove initialization lint error
    @State public allCompaniesInHub: Array<CompaniesSchemaWithId>
    // @ts-ignore to remove initialization lint error
    @State public allTodos: Array<TodosSchemaWithId>
    // @ts-ignore to remove initialization lint error
    @State public allUsers: Array<UsersSchemaWithId>
    @State public company?: CompaniesSchemaWithId
    @State public isEmbedded?: boolean
    @State public isPrinting?: boolean
    // @ts-ignore to remove initialization lint error
    @State public isUserSuperAdmin: boolean
    // @ts-ignore to remove initialization lint error
    @State public isUserTeamMember: boolean
    // @ts-ignore to remove initialization lint error
    @State public isUserImpersonator: boolean
    // @ts-ignore to remove initialization lint error
    @State isUserHubAdmin: boolean
    // @ts-ignore to remove initialization lint error
    @State public isUserProjectAdmin: boolean
    // @ts-ignore to remove initialization lint error
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Getter public loggerState: any

    public MODE: string = MODE

    @State public project?: ProjectSchemaWithId
    @State public hub?: HubSchemaWithId

    // @ts-ignore to remove initialization lint error
    @Getter public projectHasShifts: boolean
    // @ts-ignore to remove initialization lint error
    @Getter public projectShiftIds: number[]
    // @ts-ignore to remove initialization lint error
    @State public projectShifts: Array<ShiftsSchemaWithId>
    // @ts-ignore to remove initialization lint error
    @State public projectAreaSequence: Array<number>
    // @ts-ignore to remove initialization lint error
    @Getter public isBoxConnected: boolean
    // @ts-ignore to remove initialization lint error
    @Getter public isBim360DocsConnected: boolean
    // @ts-ignore to remove initialization lint error
    @Getter public isGoogleDriveConnected: boolean
    // @ts-ignore to remove initialization lint error
    @Getter public currentProjectAreaPathNames: Record<string, string>
    // @ts-ignore to remove initialization lint error
    @Getter public currentProjectAreaDetails: Array<{ title: string; areaPath: string; id: number; childrenIds: number[]; parentId: number }>

    public snackEmitter = new SnackEmitter(this)
    // @ts-ignore to remove initialization lint error
    @Getter public isStateReady: boolean

    @State public user?: UsersSchemaWithId
    // @ts-ignore to remove initialization lint error
    @State public userProjects: Array<ProjectSchemaWithId>
    // @ts-ignore to remove initialization lint error
    @State public userHubs: Array<HubSchemaWithId>
    // @ts-ignore to remove initialization lint error
    @State public isBrowserSupported: boolean

    // @ts-ignore to remove initialization lint error
    @State public projectAreas?: AreasSchemaWithId[]

    /**
     * Determines if the component is ready to be loaded or not.
     *
     * @description Out of the box it checks for the readyness of this.user, this.hub, this.project and this.company.
     * However you can make your own in the base of your component.
     * @returns {boolean} true or false
     * @example <caption>Example of how to use isComponentReady in a component template file.</caption>
     * #componentName(v-if="isComponentReady" v-cloak)
     * @example <caption>Example of how to use a custom isComponentReady getter in a component client base class file.</caption>
     * public get isComponentReady(): boolean {
     *   if (this.isStateReady) { some custom code that returns a boolean } return true
     *   else return false
     * }
     */
    public get isComponentReady(): boolean {
        if (this.isStateReady) return true
        else return false
    }

    /**
     * Gets themed button color
     *
     * @returns {string} Returns the theme primary color.
     */
    public get themedButtonColor(): string {
        if (this.$store.state.userPreferences.darkMode) return "#ffffff"

        const primaryColor = this.$vuetify.theme.themes.light.primary
        if (typeof primaryColor !== "string" || !primaryColor) return "#000000"

        return whiteOrBlackText(primaryColor) === "black" ? "#000000" : primaryColor
    }

    public get themedButtonTextColor(): string {
        return whiteOrBlackText(this.themedButtonColor) === "black" ? "#000000" : "#ffffff"
    }

    /**
     * Gets text color on primary
     *
     * @returns {string} a string containing the text color for the primary theme attribute.
     */
    public get textColorOnPrimary(): string {
        // @ts-ignore to remove initialization lint error
        const primaryColor = this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.primary : this.$vuetify.theme.themes.light.primary
        if (typeof primaryColor !== "string" || !primaryColor) return "#000000"
        return whiteOrBlackText(primaryColor) === "black" ? "#000000" : "#ffffff"
    }

    public get textColorOnListItems(): string {
        return this.$vuetify.theme.dark ? "#ffffff" : "#00263A" // this.$vuetify.theme.themes.light.primary
    }
}
