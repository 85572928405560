/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class FBadgeBase extends BaseVueComponent {
    @Prop() public content!: number | string
    @Prop(Number) public value!: number
    @Prop(String) public icon!: string
    @Prop({ default: false, type: Boolean }) public right!: boolean
    @Prop({ default: false, type: Boolean }) public overlap!: boolean
    @Prop(String) public text!: string
    @Prop(String) public subTitle!: string
    @Prop(String) public color!: string
    @Prop({ default: false, type: Boolean }) public checkbox!: boolean
    @Prop({ type: Object }) public Props!: any
    @Prop({ type: Object }) public On!: any
    @Prop(Number) public offsetX!: number
    @Prop(Number) public offsetY!: number
    @Prop({ default: false, type: Boolean }) public bordered!: boolean
    @Prop({ type: String }) public customStyle!: string

    public change(): void {
        this.$emit("change")
    }
}
