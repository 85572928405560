// To parse this data:
//
//   import { Convert, ProjectSchema } from "./file";
//
//   const projectSchema = Convert.toProjectSchema(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

/**
 * Project Schema
 */
export interface ProjectSchema {
    created_by:    number;
    date_created:  string;
    date_modified: string;
    id?:           number;
    modified_by:   number;
    data:          Data;
    }

export interface Data {
    active:               boolean;
    areas:                number[];
    attachments:          AttachmentReference[];
    building_type?:       string;
    can_add_company:      string[];
    can_create_shifts:    string[];
    can_edit_areas:       string[];
    can_invite_user:      string[];
    code?:                string;
    companies_in_project: CompaniesInProject[];
    connections:          Connections;
    daily:                Daily;
    daily_ids:            number[];
    deleted?:             boolean;
    distribution_lists?:  number[];
    holidays?:            Holiday[];
    location:             Location;
    man_hours_offset?:    number;
    name:                 string;
    planscan:             Planscan;
    quantities:           Quantities;
    quantity_ids:         number[];
    shift_ids:            number[];
    survey_templates:     SurveyTemplate[];
    tags?:                string[];
    variance_reasons?:    VarianceReason[];
    workdays:             string[];
    }

export interface AttachmentReference {
    id:   number;
    tags: string[];
    }

export interface CompaniesInProject {
    abbreviation?: string;
    active:        boolean;
    color?:        string;
    company_id:    number;
    disciplines?:  string[];
    project_logo?: AttachmentReference;
    users:         User[];
    }

export interface User {
    active:   boolean;
    id:       number;
    metadata: Metadata;
    roles:    string[];
    }

export interface Metadata {
    invite_sent: boolean;
    invited_by?: number;
    }

export interface Connections {
    bim360_docs?:  Bim360Docs;
    bim360_field?: Bim360Field;
    box?:          Box;
    google_drive?: GoogleDrive;
    }

export interface Bim360Docs {
    cloud_storage?: CloudStorage;
    }

export interface CloudStorage {
    company_id?:  number;
    connected_by: number;
    folder_id:    string;
    folder_link:  string;
    folder_name:  string;
    options:      Options;
    }

export interface Options {
    can_select?:        boolean;
    csv_data?:          boolean;
    daily_attachments?: boolean;
    daily_reports?:     boolean;
    }

export interface Bim360Field {
    connected_by?: number;
    ticket?:       string;
    }

export interface Box {
    cloud_storage?: CloudStorage;
    }

export interface GoogleDrive {
    cloud_storage?: CloudStorage;
    }

export interface Daily {
    daily_report_grouping: string;
    reporting?:            Reporting;
    settings:              DailySettings;
    }

export interface Reporting {
    scheduled: number[];
    }

export interface DailySettings {
    active:                  boolean;
    automatically_create:    boolean;
    daily_report_send_time?: string;
    lock_daily_after?:       number;
    }

export interface Holiday {
    date: string;
    name: string;
    }

export interface Location {
    address:     string;
    lat:         number;
    long:        number;
    timezone:    string;
    what3words?: string;
    }

export interface Planscan {
    schedule: number;
    settings: PlanscanSettings;
    }

export interface PlanscanSettings {
    active:                  boolean;
    can_complete_activities: string[];
    can_update_dates:        string[];
    constraint_types:        ConstraintType[];
    origin_config?:          OriginConfig;
    ppc:                     PPC;
    stickies_print_config?:  { [key: string]: any };
    variance_reasons?:       boolean;
    }

export interface ConstraintType {
    affects_ppc_calculations?: boolean;
    project_can_remove?:       boolean;
    title:                     string;
    }

export interface OriginConfig {
    p6?: P6;
    }

export interface P6 {
    currency_format:      string;
    date_format:          string;
    duration_qty_type:    string;
    show_as_percentage:   boolean;
    small_scale_qty_type: string;
    }

export interface PPC {
    calculation_method: string;
    constraint_types:   string[];
    }

export interface Quantities {
    settings: QuantitiesSettings;
    }

export interface QuantitiesSettings {
    active: boolean;
    }

export interface SurveyTemplate {
    companies_not_required: number[];
    frequency:              string[];
    template_id:            number;
    }

export interface VarianceReason {
    description?: string;
    reason:       string;
    }

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toProjectSchema(json: string): ProjectSchema {
        return cast(JSON.parse(json), r("ProjectSchema"));
    }

    public static projectSchemaToJson(value: ProjectSchema): string {
        return JSON.stringify(uncast(value, r("ProjectSchema")), null, 2);
    }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ''): never {
    const prettyTyp = prettyTypeName(typ);
    const parentText = parent ? ` on ${parent}` : '';
    const keyText = key ? ` for key "${key}"` : '';
    throw Error(`Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(val)}`);
}

function prettyTypeName(typ: any): string {
    if (Array.isArray(typ)) {
        if (typ.length === 2 && typ[0] === undefined) {
            return `an optional ${prettyTypeName(typ[1])}`;
        } else {
            return `one of [${typ.map(a => { return prettyTypeName(a); }).join(", ")}]`;
        }
    } else if (typeof typ === "object" && typ.literal !== undefined) {
        return typ.literal;
    } else {
        return typeof typ;
    }
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = '', parent: any = ''): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val, key, parent);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        const l = typs.length;
        for (let i = 0; i < l; i++) {
            const typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {}
        }
        return invalidValue(typs, val, key, parent);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases.map(a => { return l(a); }), val, key, parent);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue(l("Date"), val, key, parent);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue(l(ref || "object"), val, key, parent);
        }
        const result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps, key, ref);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps, key, ref);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val, key, parent);
    }
    if (typ === false) return invalidValue(typ, val, key, parent);
    let ref: any = undefined;
    while (typeof typ === "object" && typ.ref !== undefined) {
        ref = typ.ref;
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
            : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
            : invalidValue(typ, val, key, parent);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
    return { literal: typ };
}

function a(typ: any) {
    return { arrayItems: typ };
}

function u(...typs: any[]) {
    return { unionMembers: typs };
}

function o(props: any[], additional: any) {
    return { props, additional };
}

function m(additional: any) {
    return { props: [], additional };
}

function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    "ProjectSchema": o([
        { json: "created_by", js: "created_by", typ: 0 },
        { json: "date_created", js: "date_created", typ: "" },
        { json: "date_modified", js: "date_modified", typ: "" },
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "modified_by", js: "modified_by", typ: 0 },
        { json: "data", js: "data", typ: r("Data") },
    ], "any"),
    "Data": o([
        { json: "active", js: "active", typ: true },
        { json: "areas", js: "areas", typ: a(0) },
        { json: "attachments", js: "attachments", typ: a(r("AttachmentReference")) },
        { json: "building_type", js: "building_type", typ: u(undefined, "") },
        { json: "can_add_company", js: "can_add_company", typ: a("") },
        { json: "can_create_shifts", js: "can_create_shifts", typ: a("") },
        { json: "can_edit_areas", js: "can_edit_areas", typ: a("") },
        { json: "can_invite_user", js: "can_invite_user", typ: a("") },
        { json: "code", js: "code", typ: u(undefined, "") },
        { json: "companies_in_project", js: "companies_in_project", typ: a(r("CompaniesInProject")) },
        { json: "connections", js: "connections", typ: r("Connections") },
        { json: "daily", js: "daily", typ: r("Daily") },
        { json: "daily_ids", js: "daily_ids", typ: a(0) },
        { json: "deleted", js: "deleted", typ: u(undefined, true) },
        { json: "distribution_lists", js: "distribution_lists", typ: u(undefined, a(3.14)) },
        { json: "holidays", js: "holidays", typ: u(undefined, a(r("Holiday"))) },
        { json: "location", js: "location", typ: r("Location") },
        { json: "man_hours_offset", js: "man_hours_offset", typ: u(undefined, 3.14) },
        { json: "name", js: "name", typ: "" },
        { json: "planscan", js: "planscan", typ: r("Planscan") },
        { json: "quantities", js: "quantities", typ: r("Quantities") },
        { json: "quantity_ids", js: "quantity_ids", typ: a(0) },
        { json: "shift_ids", js: "shift_ids", typ: a(0) },
        { json: "survey_templates", js: "survey_templates", typ: a(r("SurveyTemplate")) },
        { json: "tags", js: "tags", typ: u(undefined, a("")) },
        { json: "variance_reasons", js: "variance_reasons", typ: u(undefined, a(r("VarianceReason"))) },
        { json: "workdays", js: "workdays", typ: a("") },
    ], "any"),
    "AttachmentReference": o([
        { json: "id", js: "id", typ: 3.14 },
        { json: "tags", js: "tags", typ: a("") },
    ], "any"),
    "CompaniesInProject": o([
        { json: "abbreviation", js: "abbreviation", typ: u(undefined, "") },
        { json: "active", js: "active", typ: true },
        { json: "color", js: "color", typ: u(undefined, "") },
        { json: "company_id", js: "company_id", typ: 0 },
        { json: "disciplines", js: "disciplines", typ: u(undefined, a("")) },
        { json: "project_logo", js: "project_logo", typ: u(undefined, r("AttachmentReference")) },
        { json: "users", js: "users", typ: a(r("User")) },
    ], "any"),
    "User": o([
        { json: "active", js: "active", typ: true },
        { json: "id", js: "id", typ: 0 },
        { json: "metadata", js: "metadata", typ: r("Metadata") },
        { json: "roles", js: "roles", typ: a("") },
    ], "any"),
    "Metadata": o([
        { json: "invite_sent", js: "invite_sent", typ: true },
        { json: "invited_by", js: "invited_by", typ: u(undefined, 3.14) },
    ], "any"),
    "Connections": o([
        { json: "bim360_docs", js: "bim360_docs", typ: u(undefined, r("Bim360Docs")) },
        { json: "bim360_field", js: "bim360_field", typ: u(undefined, r("Bim360Field")) },
        { json: "box", js: "box", typ: u(undefined, r("Box")) },
        { json: "google_drive", js: "google_drive", typ: u(undefined, r("GoogleDrive")) },
    ], "any"),
    "Bim360Docs": o([
        { json: "cloud_storage", js: "cloud_storage", typ: u(undefined, r("CloudStorage")) },
    ], "any"),
    "CloudStorage": o([
        { json: "company_id", js: "company_id", typ: u(undefined, 3.14) },
        { json: "connected_by", js: "connected_by", typ: 0 },
        { json: "folder_id", js: "folder_id", typ: "" },
        { json: "folder_link", js: "folder_link", typ: "" },
        { json: "folder_name", js: "folder_name", typ: "" },
        { json: "options", js: "options", typ: r("Options") },
    ], "any"),
    "Options": o([
        { json: "can_select", js: "can_select", typ: u(undefined, true) },
        { json: "csv_data", js: "csv_data", typ: u(undefined, true) },
        { json: "daily_attachments", js: "daily_attachments", typ: u(undefined, true) },
        { json: "daily_reports", js: "daily_reports", typ: u(undefined, true) },
    ], "any"),
    "Bim360Field": o([
        { json: "connected_by", js: "connected_by", typ: u(undefined, 0) },
        { json: "ticket", js: "ticket", typ: u(undefined, "") },
    ], "any"),
    "Box": o([
        { json: "cloud_storage", js: "cloud_storage", typ: u(undefined, r("CloudStorage")) },
    ], "any"),
    "GoogleDrive": o([
        { json: "cloud_storage", js: "cloud_storage", typ: u(undefined, r("CloudStorage")) },
    ], "any"),
    "Daily": o([
        { json: "daily_report_grouping", js: "daily_report_grouping", typ: "" },
        { json: "reporting", js: "reporting", typ: u(undefined, r("Reporting")) },
        { json: "settings", js: "settings", typ: r("DailySettings") },
    ], "any"),
    "Reporting": o([
        { json: "scheduled", js: "scheduled", typ: a(3.14) },
    ], "any"),
    "DailySettings": o([
        { json: "active", js: "active", typ: true },
        { json: "automatically_create", js: "automatically_create", typ: true },
        { json: "daily_report_send_time", js: "daily_report_send_time", typ: u(undefined, "") },
        { json: "lock_daily_after", js: "lock_daily_after", typ: u(undefined, 3.14) },
    ], "any"),
    "Holiday": o([
        { json: "date", js: "date", typ: "" },
        { json: "name", js: "name", typ: "" },
    ], "any"),
    "Location": o([
        { json: "address", js: "address", typ: "" },
        { json: "lat", js: "lat", typ: 3.14 },
        { json: "long", js: "long", typ: 3.14 },
        { json: "timezone", js: "timezone", typ: "" },
        { json: "what3words", js: "what3words", typ: u(undefined, "") },
    ], "any"),
    "Planscan": o([
        { json: "schedule", js: "schedule", typ: 0 },
        { json: "settings", js: "settings", typ: r("PlanscanSettings") },
    ], "any"),
    "PlanscanSettings": o([
        { json: "active", js: "active", typ: true },
        { json: "can_complete_activities", js: "can_complete_activities", typ: a("") },
        { json: "can_update_dates", js: "can_update_dates", typ: a("") },
        { json: "constraint_types", js: "constraint_types", typ: a(r("ConstraintType")) },
        { json: "origin_config", js: "origin_config", typ: u(undefined, r("OriginConfig")) },
        { json: "ppc", js: "ppc", typ: r("PPC") },
        { json: "stickies_print_config", js: "stickies_print_config", typ: u(undefined, m("any")) },
        { json: "variance_reasons", js: "variance_reasons", typ: u(undefined, true) },
    ], "any"),
    "ConstraintType": o([
        { json: "affects_ppc_calculations", js: "affects_ppc_calculations", typ: u(undefined, true) },
        { json: "project_can_remove", js: "project_can_remove", typ: u(undefined, true) },
        { json: "title", js: "title", typ: "" },
    ], "any"),
    "OriginConfig": o([
        { json: "p6", js: "p6", typ: u(undefined, r("P6")) },
    ], "any"),
    "P6": o([
        { json: "currency_format", js: "currency_format", typ: "" },
        { json: "date_format", js: "date_format", typ: "" },
        { json: "duration_qty_type", js: "duration_qty_type", typ: "" },
        { json: "show_as_percentage", js: "show_as_percentage", typ: true },
        { json: "small_scale_qty_type", js: "small_scale_qty_type", typ: "" },
    ], "any"),
    "PPC": o([
        { json: "calculation_method", js: "calculation_method", typ: "" },
        { json: "constraint_types", js: "constraint_types", typ: a("") },
    ], "any"),
    "Quantities": o([
        { json: "settings", js: "settings", typ: r("QuantitiesSettings") },
    ], "any"),
    "QuantitiesSettings": o([
        { json: "active", js: "active", typ: true },
    ], "any"),
    "SurveyTemplate": o([
        { json: "companies_not_required", js: "companies_not_required", typ: a(3.14) },
        { json: "frequency", js: "frequency", typ: a("") },
        { json: "template_id", js: "template_id", typ: 0 },
    ], "any"),
    "VarianceReason": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "reason", js: "reason", typ: "" },
    ], "any"),
};
export type ProjectSchemaWithId = ProjectSchema & RequiredId
