import http, { isHttpError } from "common/http"
import { CompaniesSchemaWithId } from "components/companies/companies.schema"
import { HubSchemaWithId } from "components/hub/hub.schema"
import { ProjectSchemaWithId } from "components/project/project.schema"
import { UsersSchemaWithId } from "components/users/users.schema"

import { FeedbackPostIssueAttachmentParams, FeedbackPostIssueData } from "./feedback"

/**
 * Feedback Create Issue Method
 *
 * @param user User schema
 * @param hub hub schema
 *  @param project Project schema
 * @param company Company schema
 * @param feedbackPostIssueData feedbackPostIssueData
 * @description Create a new feedback issue within Protest and GitLab respectively.
 * @returns FeedbackPostIssueAttachmentParams
 */
export async function feedbackPostIssue(
    user: UsersSchemaWithId,
    hub: HubSchemaWithId,
    project: ProjectSchemaWithId,
    company: CompaniesSchemaWithId,
    feedbackPostIssueData: FeedbackPostIssueData
): Promise<undefined | FeedbackPostIssueAttachmentParams> {
    if (feedbackPostIssueData.title && feedbackPostIssueData.description) {
        /**
         * Tag Feedback Description w/ Metadata
         */
        const submitterMetaData = `<br> <b>Hub</b>: &nbsp;${hub.data.name}
   <br> <b>Hub Id:</b> &nbsp;${hub.id} <br> <b>User</b>: &nbsp;${user.data.name}
     <br> <b>User Id:</b> &nbsp;${user.id} <br> <b>Email</b>: &nbsp;${user.data.email} <br> <b>Company</b>: &nbsp;${company.data.name}
       <br> <b>Company Id:</b>  &nbsp;${company.id} <br> <b>Project</b>:  &nbsp;${project.data.name}
      <br> <b>Project Id:</b>   &nbsp;${project.id}<br>`

        // eslint-disable-next-line prefer-const
        let { description, ...theRestOfFeedback } = feedbackPostIssueData
        const attachments = feedbackPostIssueData.attachments
        if (feedbackPostIssueData.attachments) {
            for (const attachment of feedbackPostIssueData.attachments)
                description = "<b>Descriprion</b> : &nbsp;" + description + `\n\n [ ` + attachment.Name + `]`
        }

        description = submitterMetaData + description

        const feedback: FeedbackPostIssueData = {
            description,
            attachments,
            ...theRestOfFeedback
        }

        const feedbackService = await http.post<FeedbackPostIssueAttachmentParams>(process.env.FLYPAPER_API_URL + "/feedback/create/issue", feedback)

        if (isHttpError(feedbackService)) return

        return feedbackService
    }
}
