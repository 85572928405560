import BaseVueComponent from "common/BaseVueComponent"
import { isNullOrWhitespace } from "common/client.utils"
import {
    getLattitudeLongitudeFromDegree,
    getLongitudeAndLatitudeFromWhat3Words,
    getWhat3WordsFromLongitudeAndLatitude,
    setProjectAddress,
    validateWhat3words
} from "common/locationUtils"
import { AddressAutoComplete, LocationDetails, ProjectLocation, What3WordsResponse } from "components/widgets/fAddress/fAddress"
import addressConfig from "components/widgets/fAddress/fAddress.config.json"
import SavingIndicator from "components/widgets/savingIndicator/savingIndicator.component.vue"
import { Component, Prop, Watch } from "vue-property-decorator"

// @ts-ignore We have to do this because of the abstract class
@Component({
    components: {
        SavingIndicator
    }
})
export default abstract class FAddress extends BaseVueComponent {
    @Prop({ default: false }) public disabled!: boolean
    @Prop({ default: () => "Address", type: String }) public label!: string
    @Prop({ default: false }) public reload!: boolean
    @Prop({ default: false }) public isValidAddress!: boolean
    @Prop({ default: "", type: String }) public value!: string
    public rules = {
        notBlank: (v: string): boolean | string => !isNullOrWhitespace(v) || "Cannot be blank."
    }
    public what3WordsAddressMap = ""
    public what3WordsAddressDetails = ""

    public regexWhat3Words =
        /^\/{0,}[^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}$/
    public invalidCharactersInLogLat = /[°NE ^a-zA-Z]/g
    public isAddressValid: boolean | null = null
    public savingStatus: "saving" | "validated" | "error" = "validated"

    public abstract showSnackEmmiter(message: string): void
    public get localAddress(): string {
        return this.value
    }

    public set localAddress(address: string) {
        this.$emit("input", address)
    }

    public get isBlankHint(): string {
        if (!this.localAddress || this.localAddress === "") return "Cannot be blank."
        return ""
    }

    @Watch("project.id", { immediate: true })
    public setWhat3words(): void {
        this.savingStatus = this.localAddress === "" ? "error" : "validated"

        if (this.project && this.project.data.location.lat && this.project.data.location.long && this.isValidAddress) {
            this.isAddressValid = true
            if (this.project.data.location.what3words)
                this.what3WordsAddressMap = this.localAddress === "" ? "" : addressConfig.what3words_url + this.project.data.location.what3words
        }
    }

    @Watch("localAddress", { immediate: true })
    public async onAddressChanges(newAdd: string, oldAdd: string | undefined): Promise<void> {
        if (oldAdd === undefined) return
        if (this.localAddress === "") {
            // HACK: we need to update project to validate when address is blank
            this.$emit("placeChanged", { latitude: -1, longitude: -1, what3words: undefined })
            this.what3WordsAddressMap = ""
            this.isAddressValid = false
            this.savingStatus = "error"
        }

        await this.checkIfLattitudeLongitudeOrWhat3words(this.localAddress)
    }

    public async checkIfLattitudeLongitudeOrWhat3words(localAddress: string): Promise<void> {
        const longitudeAndLatitude = getLattitudeLongitudeFromDegree(localAddress)

        if (longitudeAndLatitude && longitudeAndLatitude.latitude && longitudeAndLatitude.longitude) {
            this.savingStatus = "saving"
            this.isAddressValid = null
            this.what3WordsAddressMap = ""
            const addressResponse = await getWhat3WordsFromLongitudeAndLatitude(
                Number(longitudeAndLatitude.latitude),
                Number(longitudeAndLatitude.longitude)
            )

            if (!addressResponse) {
                this.isAddressValid = false
                this.validateAddress()
                return
            }

            this.validateAddress()

            this.what3WordsAddressMap = addressResponse.map

            this.onWhat3WordsChanged(addressResponse, localAddress)

            return
        } else if (validateWhat3words(localAddress)) {
            this.savingStatus = "saving"
            this.isAddressValid = null
            this.what3WordsAddressMap = ""
            const addressResponse = await getLongitudeAndLatitudeFromWhat3Words(localAddress)

            if (!addressResponse) {
                this.isAddressValid = false
                this.validateAddress()
                return
            }

            this.validateAddress()
            this.what3WordsAddressMap = addressResponse.map

            this.onWhat3WordsChanged(addressResponse, localAddress)
            return
        } else {
            this.validateAddress()
        }
    }

    public validateAddress(): void {
        if (this.isAddressValid === true) this.savingStatus = "validated"
        else if (this.isAddressValid === false) this.savingStatus = "error"
        else this.savingStatus = "saving"
    }

    public openWhat3WordsAddress(): void {
        window.open(this.what3WordsAddressMap, "_blank")
    }

    public async onAddressSelected(data: AddressAutoComplete): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (data.latitude !== undefined) {
            this.what3WordsAddressMap = ""
            const projectAddress: ProjectLocation = {
                location: { latitude: -1, longitude: -1, what3words: "", address: "" }
            }

            projectAddress.location.latitude = data.latitude
            projectAddress.location.longitude = data.longitude
            // projectAddress.location.address = this.localAddress
            // this.validateAndEmitAddress(projectAddress.location)

            this.savingStatus = "saving"
            this.isAddressValid = null

            const addressResponse = await getWhat3WordsFromLongitudeAndLatitude(
                Number(projectAddress.location.latitude),
                Number(projectAddress.location.longitude)
            )

            if (!addressResponse) {
                this.isAddressValid = false
                return
            }

            this.what3WordsAddressMap = addressResponse.map
            this.showSnackEmmiter("Latitude and logitude are valid")
            this.onWhat3WordsChanged(addressResponse)
            return
        }
    }

    public async onWhat3WordsChanged(data: What3WordsResponse, localAddress?: string): Promise<void> {
        this.savingStatus = "saving"
        this.isAddressValid = null

        let projectAddress: ProjectLocation = {
            location: { latitude: -1, longitude: -1, what3words: "", address: "" }
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (data.coordinates !== undefined) {
            projectAddress = setProjectAddress(data, localAddress ? localAddress : this.localAddress)
            this.validateAndEmitAddress(projectAddress.location)
            return
        }

        if (projectAddress.location.latitude !== -1 && projectAddress.location.latitude !== -1) {
            this.validateAndEmitAddress(projectAddress.location)
        } else {
            this.isAddressValid = false
            this.validateAddress()
        }
    }

    public validateAndEmitAddress(location: LocationDetails): void {
        this.isAddressValid = true
        this.validateAddress()

        this.$emit("placeChanged", location)
    }
}
