import Logger from "common/Logger"; const LOGGER = new Logger("protest.store.ts");/**
 * Protest Vuex Store
 *
 * @public
 */
import { Sessions } from "common/client.utils"
import http, { HttpError, isHttpError } from "common/http"
import CompaniesDb from "components/companies/companies.db"
import { CompaniesSchemaWithId } from "components/companies/companies.schema"
import DailyDb from "components/daily/daily.db"
import WorklogsDb from "components/daily/worklogs/worklogs.db"
import HubDb from "components/hub/hub.db"
import { HubSchemaWithId } from "components/hub/hub.schema"
import AreasDb from "components/project/areas/areas.db"
import { AreasSchemaWithId } from "components/project/areas/areas.schema"
import favoriteprojectDb from "components/project/favoriteProjects/favoriteProjects.db"
import { FavoriteProjectsSchema } from "components/project/favoriteProjects/favoriteProjects.schema"
import ProjectDb from "components/project/project.db"
import { ProjectSchemaWithId } from "components/project/project.schema"
import ShiftsDb from "components/project/shifts/shifts.db"
import { ShiftsSchemaWithId } from "components/project/shifts/shifts.schema"
import UsersDb from "components/users/users.db"
import { UsersSchemaWithId } from "components/users/users.schema"
import compact from "lodash/compact"
import isEqual from "lodash/isEqual"
import sortBy from "lodash/sortBy"
import uniq from "lodash/uniq"
import { DateTime } from "luxon"
import Vue from "vue"
import Vuex from "vuex"

import { getAllChildAreas } from "./project/areas/areas.utils"

type UserPreferences = {
    compactMode: boolean
    darkMode: boolean
    stickyMode: boolean
    isActivityListView: boolean
    isConstraintListView: boolean
    isReviewListView: boolean
    isMilestoneListView: boolean
    isTimelineListView: boolean
    isWorklogListView: boolean
}

const defaultState = () => {
    return {
        hub: undefined as HubSchemaWithId | undefined,
        company: undefined as CompaniesSchemaWithId | undefined,
        user: undefined as UsersSchemaWithId | undefined,
        project: undefined as ProjectSchemaWithId | undefined,
        favoriteProjects: [] as Array<ProjectSchemaWithId>,
        projectShifts: [] as Array<ShiftsSchemaWithId>,
        projectAreas: [] as Array<AreasSchemaWithId>,
        projectAreaSequence: [] as Array<number>,
        allCompaniesInHub: [] as Array<CompaniesSchemaWithId>,
        allUsers: [] as Array<UsersSchemaWithId>,
        userProjects: [] as Array<ProjectSchemaWithId>,
        userHubs: [] as Array<HubSchemaWithId>,
        projectWorklogTags: [] as string[],
        isEmbedded: false,
        showInactiveHubsProjects: false,
        isPrinting: false,
        isUserProjectAdmin: false,
        isUserSuperAdmin: false,
        isUserTeamMember: false,
        isUserHubAdmin: false,
        isUserImpersonator: false,
        loaded: false,
        pageOffSetLimit: 500,
        showCamera: false,
        uploadData: {
            uploadUrl: "",
            uploadingTo: {
                component: "",
                id: 0
            }
        },
        pendingUploads: [] as Array<PendingUpload>,
        showAudioRecorder: false,
        audioRecorderUploadUrl: "",
        isBrowserSupported: true,
        browserName: "",

        lastWorkingDailyDate: DateTime.local().toISODate(),
        userPreferences: {
            compactMode: false,
            darkMode: false,
            stickyMode: true,
            isActivityListView: false,
            isConstraintListView: false,
            isReviewListView: false,
            isMilestoneListView: false,
            isTimelineListView: false,
            isWorklogListView: false
        } as UserPreferences
    }
}

Vue.use(Vuex)
/** Protest Vuex Store */
export const store = new Vuex.Store({
    state: {
        ...defaultState()
    },
    mutations: {
        setLoaded(state, loaded: boolean): void {
            state.loaded = loaded
        },
        setPageOffSetLimit(state, pageOffSetLimit: number): void {
            state.pageOffSetLimit = pageOffSetLimit
        },
        setProject(state, project: ProjectSchemaWithId): void {
            state.project = project
        },
        setFavoriteProjects(state, projects: ProjectSchemaWithId[]): void {
            state.favoriteProjects = projects
        },
        setProjectShifts(state, shifts: ShiftsSchemaWithId[]): void {
            state.projectShifts = shifts
        },
        setCompany(state, company: CompaniesSchemaWithId): void {
            state.company = company
        },
        setUser(state, user: UsersSchemaWithId): void {
            state.user = user
        },
        setHub(state, hub: HubSchemaWithId | undefined): void {
            state.hub = hub
        },
        setAllCompaniesInHub(state, companies: CompaniesSchemaWithId[]): void {
            state.allCompaniesInHub = companies
        },
        setAllUsers(state, users: UsersSchemaWithId[]): void {
            state.allUsers = users
        },
        setUserProjects(state, projects: ProjectSchemaWithId[]): void {
            state.userProjects = projects
        },
        setUserHubs(state, hubs: HubSchemaWithId[]): void {
            state.userHubs = hubs
        },
        clearState(state): void {
            Object.assign(state, defaultState())
        },
        setEmbedded(state, isEmbedded: boolean): void {
            state.isEmbedded = isEmbedded
        },
        setPrinting(state, isPrinting: boolean): void {
            state.isPrinting = isPrinting
        },
        setProjectWorklogTags(state, tags: string[]): void {
            state.projectWorklogTags = tags
        },
        setProjectAreas(state, areas: AreasSchemaWithId[]): void {
            state.projectAreas = areas
        },
        setProjectAreaSequence(state, areas: number[]): void {
            state.projectAreaSequence = areas
        },
        setIsUserProjectAdmin(state, isAdmin: boolean): void {
            state.isUserProjectAdmin = isAdmin
        },
        setShowInactiveHubsProjects(state, isInactive: boolean): void {
            state.showInactiveHubsProjects = isInactive
        },
        setisUserSuperAdmin(state, isAdmin: boolean): void {
            state.isUserSuperAdmin = isAdmin
        },
        setisUserTeamMember(state, isTeamMember: boolean): void {
            state.isUserTeamMember = isTeamMember
        },
        setIsUserImpersonator(state, isImpersonator: boolean): void {
            state.isUserImpersonator = isImpersonator
        },
        setIsUserHubAdmin(state, isAdmin: boolean): void {
            state.isUserHubAdmin = isAdmin
        },
        setDarkMode(state, darkMode): void {
            state.userPreferences.darkMode = darkMode
        },
        setIsTimelineListView(state, isListView): void {
            state.userPreferences.isTimelineListView = isListView
        },
        setIsActivityListView(state, isListView): void {
            state.userPreferences.isActivityListView = isListView
        },
        setIsConstraintListView(state, isListView): void {
            state.userPreferences.isConstraintListView = isListView
        },
        setIsReviewListView(state, isListView): void {
            state.userPreferences.isReviewListView = isListView
        },
        setIsMilestoneListView(state, isListView): void {
            state.userPreferences.isMilestoneListView = isListView
        },
        setIsWorklogListView(state, isListView): void {
            state.userPreferences.isWorklogListView = isListView
        },
        setLastDailyDate(state, lastWorkingDate): void {
            state.lastWorkingDailyDate = lastWorkingDate
        },

        setStickyMode(state, stickyMode): void {
            state.userPreferences.stickyMode = stickyMode
        },
        setShowCamera(state, value): void {
            state.showCamera = value
        },
        setUploadData(state, uploadData: UploadData): void {
            state.uploadData = uploadData
        },
        setShowAudioRecorder(state, value): void {
            state.showAudioRecorder = value
        },
        setAudioRecorderUploadUrl(state, uploadUrl: string): void {
            state.audioRecorderUploadUrl = uploadUrl
        },
        setBrowserName(state, value): void {
            state.browserName = value
        },
        setBrowserSupport(state, value): void {
            state.isBrowserSupported = value
        },
        setCompactMode(state, compactMode): void {
            state.userPreferences.compactMode = compactMode
        },
        removePendingUpload(state, filepathIdentifier: string): void {
            const index = state.pendingUploads.findIndex((x) => x.filepathIdentifier === filepathIdentifier)
            if (index === -1) return
            state.pendingUploads.splice(index, 1)
        },
        addPendingUpload(state, pendingUpload: PendingUpload): void {
            const index = state.pendingUploads.findIndex((x) => isEqual(pendingUpload, x))
            if (index !== -1) return
            state.pendingUploads.push(pendingUpload)
        }
    },
    actions: {
        showCamera(state, uploadData: UploadData): void {
            state.commit("setUploadData", uploadData)
            state.commit("setShowCamera", true)
        },
        showAudioRecorder(state, uploadData: UploadData): void {
            state.commit("setUploadData", uploadData)
            state.commit("setShowAudioRecorder", true)
        },
        clearState(store): void {
            store.commit("clearState")
        },
        async queryAndReplaceHub(state, hubId: number | undefined): Promise<void> {
            if (hubId === 0 || !hubId) {
                state.commit("setHub", undefined)
                state.dispatch("determineIfHubAdmin")
                state.dispatch("queryForAllCompaniesInHub")
                return
            }

            const hubResponse = await HubDb.read(hubId)

            if (isHttpError(hubResponse)) {
                LOGGER.error("[STORE] Failed to read hub during query/replace.", { error: hubResponse, hubId, userId: state.state.user?.id ?? -1 })
                return
            }

            if (hubResponse.length === 0) return

            state.commit("setHub", hubResponse[0])
            state.dispatch("determineIfHubAdmin")
            state.dispatch("queryForAllCompaniesInHub")
        },
        async queryAndReplaceProject(state, projectId: number): Promise<void> {
            if (projectId === 0) return

            const projectResponse = await ProjectDb.read(projectId)

            if (isHttpError(projectResponse)) {
                LOGGER.error("[STORE] Failed to read project during query/replace.", {
                    error: projectResponse,
                    projectId,
                    userId: state.state.user?.id ?? -1
                })

                state.dispatch("clearState")
                return
            }

            if (projectResponse.length === 0) return

            const userCompany = projectResponse[0].data.companies_in_project.find((x) =>
                x.users.some((x) => x.id === state.state.user?.id && x.active === true)
            )

            if (userCompany) {
                const companyResponse = await CompaniesDb.read(userCompany.company_id)
                if (isHttpError(companyResponse)) {
                    LOGGER.error("Failed to read user company during query/replace.", {
                        error: companyResponse,
                        projectId,
                        companyId: userCompany.company_id,
                        userId: state.state.user?.id ?? -1
                    })

                    return
                }

                state.commit("setCompany", companyResponse[0])
            }

            state.commit("setProject", projectResponse[0])
            state.dispatch("determineIfProjectAdmin")
            state.dispatch("queryForAllProjectWorklogTags")
            state.dispatch("queryAndReplaceProjectShifts")
            state.dispatch("determineIfCompanyTeamMember")
            state.dispatch("queryAndReplaceProjectAreas")
        },
        async determineIfImpersonator(store): Promise<void> {
            const isImpersonator = await http.get<boolean>(`${process.env.FLYPAPER_API_URL}/auth/isImpersonator`)
            if (isHttpError(isImpersonator)) return
            store.commit("setIsUserImpersonator", isImpersonator)
        },
        async determineIfProtestAdmin(store): Promise<void> {
            const isSuperAdmin = await http.get<boolean>(`${process.env.FLYPAPER_API_URL}/auth/isSuperAdmin`)
            if (isHttpError(isSuperAdmin)) return
            store.commit("setisUserSuperAdmin", isSuperAdmin)
        },
        async determineIfCompanyTeamMember(store): Promise<void> {
            if (!store.state.company) return
            if (!store.state.project) return
            if (!store.state.user) return

            const companyIndex = store.state.project.data.companies_in_project.findIndex((company) => company.company_id === store.state.company?.id)
            if (companyIndex === -1) return
            const userIndex = store.state.project.data.companies_in_project[companyIndex].users.findIndex((user) => user.id === store.state.user?.id)
            if (userIndex === -1) return
            const isTeamMember = store.state.project.data.companies_in_project[companyIndex].users[userIndex].roles.includes("team member")
            store.commit("setisUserTeamMember", isTeamMember)
        },

        async determineIfProjectAdmin(store): Promise<void> {
            if (!store.state.project) return

            const isProjectAdmin = await http.get<boolean>(`${process.env.FLYPAPER_API_URL}/project/is/user/admin/${store.state.project.id}`)

            if (isHttpError(isProjectAdmin)) return

            store.commit("setIsUserProjectAdmin", isProjectAdmin)
        },
        async determineIfHubAdmin(store): Promise<void> {
            if (!store.state.hub) {
                store.commit("setIsUserHubAdmin", false)
                return
            }

            const isHubAdmin = await http.get<boolean>(`${process.env.FLYPAPER_API_URL}/hub/is/user/admin/${store.state.hub.id}`)

            if (isHttpError(isHubAdmin)) return

            store.commit("setIsUserHubAdmin", isHubAdmin)
        },
        async queryAndReplaceProjectShifts(store): Promise<void> {
            const project = store.state.project
            if (!project || project.data.shift_ids.length === 0) {
                store.commit("setProjectShifts", [])
                return
            }

            const shiftResponse = await ShiftsDb.readAll({
                filter: {
                    id: project.data.shift_ids
                }
            })

            if (isHttpError(shiftResponse)) {
                store.commit("setProjectShifts", [])
                return
            }

            store.commit("setProjectShifts", shiftResponse)
        },
        async queryAndReplaceCompany(state, companyId: number): Promise<void> {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (companyId === undefined || companyId === 0) return

            const companyResponse = await CompaniesDb.read(companyId)
            if (isHttpError(companyResponse)) return

            if (companyResponse.length === 0) return

            state.commit("setCompany", companyResponse[0])
            state.dispatch("determineIfCompanyAdmin")
        },
        async replaceCurrentUser({ state, commit, dispatch }, user: UsersSchemaWithId): Promise<void> {
            if (!state.user) return
            if (user.data.current_project !== state.user.data.current_project) {
                dispatch("queryForAllProjects")
                dispatch("queryAndReplaceProject", user.data.current_project)
            }

            if (user.data.current_hub !== state.user.data.current_hub) {
                dispatch("queryForAllHubs")
                dispatch("queryAndReplaceHub", user.data.current_hub)
            }

            if (user.id === state.user.id) commit("setUser", user)
        },
        async updateOrAddToAllUsers({ state, commit, dispatch }, user: UsersSchemaWithId): Promise<void> {
            const allUsers = [...state.allUsers]
            const index = allUsers.findIndex((x) => x.id === user.id)
            if (index >= 0) allUsers.splice(index, 1, user)
            else allUsers.push(user)

            commit("setAllUsers", allUsers)
        },
        async queryAndReplaceUser({ state, commit, dispatch }, userId: number): Promise<boolean> {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (userId === undefined || userId === 0) return false

            const userResponse = await UsersDb.read(userId)
            if (isHttpError(userResponse)) {
                LOGGER.error("[STORE] Could not set user during login.", { error: userResponse, userId })
                return false
            }

            const user = userResponse[0]

            commit("setUser", user)

            const conditionalPromises = []
            if (user.data.current_project !== state.project?.id)
                conditionalPromises.push(dispatch("queryAndReplaceProject", user.data.current_project))
            if (user.data.current_hub !== state.hub?.id) conditionalPromises.push(dispatch("queryAndReplaceHub", user.data.current_hub))

            await Promise.all([
                dispatch("determineIfProtestAdmin"),
                dispatch("determineIfImpersonator"),
                dispatch("queryForAllCompaniesInHub"),
                dispatch("queryForAllUsers"),
                dispatch("queryForAllProjects"),
                dispatch("queryForAllHubs"),
                dispatch("queryForFavoriteProjects"),
                ...conditionalPromises
            ])

            return true
        },
        async queryForAllProjectWorklogTags({ state, commit }): Promise<void> {
            if (!state.project) return

            const dailies = await DailyDb.readAll({
                filter: {
                    id: state.project.data.daily_ids
                }
            })

            if (isHttpError(dailies)) return

            const worklogIds = dailies.reduce((worklogIds, daily) => {
                worklogIds.push(...daily.data.worklog_ids)
                return worklogIds
            }, [] as number[])

            const worklogs = await WorklogsDb.readAll({
                filter: {
                    id: worklogIds
                }
            })

            if (isHttpError(worklogs)) return

            const tags = worklogs.reduce((tags, worklog) => {
                tags.push(...worklog.data.tags)
                return tags
            }, [] as string[])

            commit("setProjectWorklogTags", uniq(tags))
        },
        async queryForAllCompaniesInHub({ state, commit }): Promise<void> {
            if (!state.hub) return

            const allCompaniesInHub = await CompaniesDb.readAll({
                filter: {
                    id: state.hub.data.companies
                }
            })

            if (isHttpError(allCompaniesInHub)) {
                LOGGER.error("[STORE] Failed to get all companies in hub.", { error: allCompaniesInHub, userId: state.user?.id ?? -1 })
                return
            }

            commit("setAllCompaniesInHub", allCompaniesInHub)
        },
        async queryForAllUsers(state): Promise<void> {
            const allUsers = await UsersDb.readAll()
            if (isHttpError(allUsers)) return

            state.commit("setAllUsers", allUsers)
        },
        async queryForAllProjects(state): Promise<void> {
            const userProjects = await ProjectDb.readAll()
            if (isHttpError(userProjects)) return

            state.commit(
                "setUserProjects",
                sortBy(userProjects, (x) => x.data.name)
            )
        },
        async queryForFavoriteProjects(state): Promise<void> {
            // get the FavouriteProject data for current User
            const favoriteProjectsData: HttpError | (FavoriteProjectsSchema & RequiredId)[] = await favoriteprojectDb.readAll({
                filter: {
                    "data.user": this.state.user?.id
                }
            })

            if (isHttpError(favoriteProjectsData)) return

            if (Object.keys(favoriteProjectsData).length) {
                const projectIds = favoriteProjectsData[0].data.projects
                const Projects = await ProjectDb.readAll({
                    filter: {
                        id: projectIds
                    }
                })

                if (isHttpError(Projects)) return
                state.commit("setFavoriteProjects", Projects)
            }
        },
        async queryAndReplaceProjectAreas(state): Promise<void> {
            if (!state.state.project) return
            const allAreaIds: number[] = []
            for (const parentArea of state.state.project.data.areas) {
                allAreaIds.push(parentArea)
                const allAreas = await getAllChildAreas(parentArea)
                for (const area of allAreas) allAreaIds.push(area)
            }

            const allAreas = await Promise.all(
                state.state.project.data.areas.map(async (x: number) => {
                    const areas = await AreasDb.readChildren(x, "parent_area")
                    if (isHttpError(areas)) return
                    return areas
                })
            )

            if (isHttpError(allAreas)) return
            state.commit("setProjectAreas", compact(allAreas).flat())
            state.commit("setProjectAreaSequence", allAreaIds)
        },
        async updateProjectAreaIfNeeded(state, data: AreasSchemaWithId): Promise<void> {
            if (!state.state.project) return
            const allAreaIds: number[] = []
            const index = state.state.projectAreas.findIndex((x) => x.id === data.id)
            if (index === -1) return
            state.commit(
                "setProjectAreas",
                state.state.projectAreas.map((x) => (x.id === data.id ? data : x))
            )

            for (const parentArea of state.state.project.data.areas) {
                allAreaIds.push(parentArea)
                const allAreas = await getAllChildAreas(parentArea)
                for (const area of allAreas) allAreaIds.push(area)
            }

            state.commit("setProjectAreaSequence", allAreaIds)
        },
        async queryForAllHubs(state): Promise<void> {
            const userHubs = await HubDb.readAll()
            if (isHttpError(userHubs)) return

            state.commit(
                "setUserHubs",
                sortBy(userHubs, (x) => x.data.name)
            )
        },
        async hydrate(store): Promise<boolean> {
            const sessionUserId = await new Sessions().getSessionUserId()
            if (sessionUserId === 0) return false
            const success = await store.dispatch("queryAndReplaceUser", sessionUserId)
            store.commit("setLoaded", true)
            return success
        },
        async signOutBox(): Promise<void> {
            const userResponse = await http.get<void>(`${process.env.FLYPAPER_API_URL}/connections/box/oauth/signout`)
            if (isHttpError(userResponse)) return
            return
        },

        async signOutDocs(store): Promise<void> {
            await http.get<void>(`${process.env.FLYPAPER_API_URL}/connections/autodesk/oauth/signOut`)
        },

        async signOutGoogleDrive(): Promise<void> {
            await http.get<void>(`${process.env.FLYPAPER_API_URL}/connections/googleDrive/oauth/signOut`)
        },

        async getBoxAuthUrl(store, query = ""): Promise<void> {
            let authorizeUrl: string
            if (store.state.isEmbedded) {
                authorizeUrl = process.env.FLYPAPER_API_URL + "/connections/box/authorizebox?redirect_uri=mobile"
            } else {
                authorizeUrl =
                    process.env.FLYPAPER_API_URL +
                    "/connections/box/authorizebox?redirect_uri=" +
                    encodeURIComponent(window.location.pathname + query)
            }

            const boxUrl = await http.get<{ url: string }>(authorizeUrl)
            if (isHttpError(boxUrl)) return

            if (store.state.isEmbedded) {
                // @ts-ignore bad typing for nsWebInterface
                const oWebViewInterface = window.nsWebViewInterface
                oWebViewInterface.emit("startBoxAuth", boxUrl.url)
                return
            }

            window.location.href = boxUrl.url
        },

        async getBim360DocsAuthUrl(store, query = ""): Promise<void> {
            const originalUrl = window.location.pathname + query
            const url = await http.get<string>(
                process.env.FLYPAPER_API_URL + "/connections/autodesk/oauth/url?originalUrl=" + encodeURIComponent(originalUrl)
            )

            if (isHttpError(url)) return
            window.location.href = url
        },

        async getGoogleDriveAuthUrl(store, query = ""): Promise<void> {
            const originalUrl = window.location.pathname + query
            const url = await http.get<string>(
                process.env.FLYPAPER_API_URL + "/connections/googleDrive/oauth/url?originalUrl=" + encodeURIComponent(originalUrl)
            )

            if (isHttpError(url)) return
            window.location.href = url
        }
    },
    getters: {
        isStateReady(state): boolean {
            return state.user !== undefined
        },
        isBoxConnected(state): boolean {
            return state.user?.data.tokens.box?.access_token !== undefined
        },
        isBim360DocsConnected(state): boolean {
            return state.user?.data.tokens.bim360_docs?.access_token !== undefined
        },
        isGoogleDriveConnected(state): boolean {
            return state.user?.data.tokens.google_drive?.access_token !== undefined
        },
        currentCompanyName(state): string {
            if (state.user) {
                if (!state.company) return "Company not set."
                else return state.company.data.name || "Company not set"
            }

            return ""
        },
        currentProjectName(state): string {
            if (state.user) {
                if (state.user.data.current_project === 0) return "Project not set."
                else return state.project?.data.name ?? "Project not set"
            }

            return ""
        },
        currentProjectAreaPathNames(state): Record<string, string> {
            if (!state.project) return {}
            if (state.projectAreas.length === 0) return {}
            const buildAreaNamePath = (parentId: number, path: string): string => {
                if (parentId === -1) return path
                const parentArea = state.projectAreas.find((x) => x.id === parentId)
                if (!parentArea) return path
                const updatedPath = parentArea.data.title + " > " + path
                const grandParent = parentArea.data.parent_area
                if (grandParent === -1) return updatedPath
                return buildAreaNamePath(grandParent, updatedPath)
            }

            return state.projectAreas.reduce((areaPaths, area) => {
                const areaPath = area.data.title
                areaPaths[area.id] = buildAreaNamePath(area.data.parent_area, areaPath)

                return areaPaths
            }, {} as Record<string, string>)
        },
        currentProjectAreaDetails(state): Array<{
            title: string
            areaPath: string
            id: number
            childrenIds: number[]
            parentId: number
        }> {
            if (!state.project) return []
            if (state.projectAreas.length === 0) return []
            const buildAreaNamePath = (parentId: number, path: string): string => {
                if (parentId === -1) return path
                const parentArea = state.projectAreas.find((x) => x.id === parentId)
                if (!parentArea) return path
                const updatedPath = parentArea.data.title + " > " + path
                const grandParent = parentArea.data.parent_area
                if (grandParent === -1) return updatedPath
                return buildAreaNamePath(grandParent, updatedPath)
            }

            const res: { title: string; areaPath: string; id: number; childrenIds: number[]; parentId: number }[] = []
            state.projectAreas.forEach((area) => {
                if (area.data.title) {
                    res.push({
                        title: area.data.title,
                        areaPath: buildAreaNamePath(area.data.parent_area, area.data.title),
                        id: area.id,
                        childrenIds: area.data.sub_areas,
                        parentId: area.data.parent_area
                    })
                }
            })

            return res
        },
        currentHubName(state): string {
            if (state.user) {
                if (state.user.data.current_hub === 0) return "Hub Not Set"
                else return state.hub?.data.name ?? "Hub Not Set"
            }

            return ""
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        loggerState(state): any {
            return {
                user: state.user,
                hub: state.hub,
                project: state.project,
                company: state.company
            }
        },
        showPickHub(state): boolean {
            return false
        },
        noHubs(state): boolean {
            if (!state.user) return false

            return state.userHubs.length === 0
        },
        noProjects(state): boolean {
            if (!state.user) return false

            return state.userProjects.length === 0
        },
        projectHasShifts(state, getters): boolean {
            return state.projectShifts.length > 0
        },
        projectShiftIds(state): number[] {
            return state.project ? state.project.data.shift_ids : []
        }
    }
})
