// To parse this data:
//
//   import { Convert, UsersSchema } from "./file";
//
//   const usersSchema = Convert.toUsersSchema(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

/**
 * User Schema
 */
export interface UsersSchema {
    created_by:    number;
    date_created:  string;
    date_modified: string;
    id?:           number;
    modified_by:   number;
    data:          Data;
    }

export interface Data {
    access:           string[];
    active:           boolean;
    current_hub?:     number;
    current_project:  number;
    email:            string;
    email_verified?:  boolean;
    flags:            Flags;
    login_count:      number;
    metadata?:        Metadata;
    name:             string;
    picture:          string;
    project_settings: ProjectSetting[];
    roles:            string[];
    todos:            number[];
    tokens:           Tokens;
    }

export interface Flags {
    accepted_license_agreement?:     boolean;
    beta_tester?:                    boolean;
    confirmed_profile?:              boolean;
    has_requested_account_deletion?: boolean;
    onboarded?:                      boolean;
    }

export interface Metadata {
    campaign?: string;
    }

export interface ProjectSetting {
    connections:   Connections;
    id:            number;
    notifications: Notifications;
    }

export interface Connections {
    bim360_docs?:  ConnectionsBim360Docs;
    bim360_field?: Bim360Field;
    box?:          ConnectionsBox;
    google_drive?: ConnectionsGoogleDrive;
    }

export interface ConnectionsBim360Docs {
    cloud_storage?: CloudStorage;
    }

export interface CloudStorage {
    company_id?:  number;
    connected_by: number;
    folder_id:    string;
    folder_link:  string;
    folder_name:  string;
    options:      Options;
    }

export interface Options {
    can_select?:        boolean;
    csv_data?:          boolean;
    daily_attachments?: boolean;
    daily_reports?:     boolean;
    }

export interface Bim360Field {
    connected_by?: number;
    ticket?:       string;
    }

export interface ConnectionsBox {
    cloud_storage?: CloudStorage;
    }

export interface ConnectionsGoogleDrive {
    cloud_storage?: CloudStorage;
    }

export interface Notifications {
    email: boolean;
    }

export interface Tokens {
    android_push:  string[];
    api?:          API;
    bim360_docs?:  TokensBim360Docs;
    box?:          TokensBox;
    google_drive?: TokensGoogleDrive;
    ios_push:      string[];
    }

export interface API {
    access: string[];
    active: boolean;
    key:    string;
    uuid:   string;
    }

export interface TokensBim360Docs {
    access_token:  string;
    expires_at_ms: number;
    refresh_token: string;
    account_id?:   string;
    token_type?:   string;
    }

export interface TokensBox {
    access_token:        string;
    expires_at_ms:       number;
    refresh_token:       string;
    access_token_ttl_ms: number;
    acquired_at_ms:      number;
    csrf?:               string;
    redirect?:           string;
    }

export interface TokensGoogleDrive {
    access_token:  string;
    expires_at_ms: number;
    refresh_token: string;
    }

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toUsersSchema(json: string): UsersSchema {
        return cast(JSON.parse(json), r("UsersSchema"));
    }

    public static usersSchemaToJson(value: UsersSchema): string {
        return JSON.stringify(uncast(value, r("UsersSchema")), null, 2);
    }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ''): never {
    const prettyTyp = prettyTypeName(typ);
    const parentText = parent ? ` on ${parent}` : '';
    const keyText = key ? ` for key "${key}"` : '';
    throw Error(`Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(val)}`);
}

function prettyTypeName(typ: any): string {
    if (Array.isArray(typ)) {
        if (typ.length === 2 && typ[0] === undefined) {
            return `an optional ${prettyTypeName(typ[1])}`;
        } else {
            return `one of [${typ.map(a => { return prettyTypeName(a); }).join(", ")}]`;
        }
    } else if (typeof typ === "object" && typ.literal !== undefined) {
        return typ.literal;
    } else {
        return typeof typ;
    }
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = '', parent: any = ''): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val, key, parent);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        const l = typs.length;
        for (let i = 0; i < l; i++) {
            const typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {}
        }
        return invalidValue(typs, val, key, parent);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases.map(a => { return l(a); }), val, key, parent);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue(l("Date"), val, key, parent);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue(l(ref || "object"), val, key, parent);
        }
        const result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps, key, ref);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps, key, ref);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val, key, parent);
    }
    if (typ === false) return invalidValue(typ, val, key, parent);
    let ref: any = undefined;
    while (typeof typ === "object" && typ.ref !== undefined) {
        ref = typ.ref;
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
            : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
            : invalidValue(typ, val, key, parent);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
    return { literal: typ };
}

function a(typ: any) {
    return { arrayItems: typ };
}

function u(...typs: any[]) {
    return { unionMembers: typs };
}

function o(props: any[], additional: any) {
    return { props, additional };
}

function m(additional: any) {
    return { props: [], additional };
}

function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    "UsersSchema": o([
        { json: "created_by", js: "created_by", typ: 0 },
        { json: "date_created", js: "date_created", typ: "" },
        { json: "date_modified", js: "date_modified", typ: "" },
        { json: "id", js: "id", typ: u(undefined, 0) },
        { json: "modified_by", js: "modified_by", typ: 0 },
        { json: "data", js: "data", typ: r("Data") },
    ], "any"),
    "Data": o([
        { json: "access", js: "access", typ: a("") },
        { json: "active", js: "active", typ: true },
        { json: "current_hub", js: "current_hub", typ: u(undefined, 0) },
        { json: "current_project", js: "current_project", typ: 0 },
        { json: "email", js: "email", typ: "" },
        { json: "email_verified", js: "email_verified", typ: u(undefined, true) },
        { json: "flags", js: "flags", typ: r("Flags") },
        { json: "login_count", js: "login_count", typ: 3.14 },
        { json: "metadata", js: "metadata", typ: u(undefined, r("Metadata")) },
        { json: "name", js: "name", typ: "" },
        { json: "picture", js: "picture", typ: "" },
        { json: "project_settings", js: "project_settings", typ: a(r("ProjectSetting")) },
        { json: "roles", js: "roles", typ: a("") },
        { json: "todos", js: "todos", typ: a(0) },
        { json: "tokens", js: "tokens", typ: r("Tokens") },
    ], "any"),
    "Flags": o([
        { json: "accepted_license_agreement", js: "accepted_license_agreement", typ: u(undefined, true) },
        { json: "beta_tester", js: "beta_tester", typ: u(undefined, true) },
        { json: "confirmed_profile", js: "confirmed_profile", typ: u(undefined, true) },
        { json: "has_requested_account_deletion", js: "has_requested_account_deletion", typ: u(undefined, true) },
        { json: "onboarded", js: "onboarded", typ: u(undefined, true) },
    ], "any"),
    "Metadata": o([
        { json: "campaign", js: "campaign", typ: u(undefined, "") },
    ], "any"),
    "ProjectSetting": o([
        { json: "connections", js: "connections", typ: r("Connections") },
        { json: "id", js: "id", typ: 0 },
        { json: "notifications", js: "notifications", typ: r("Notifications") },
    ], "any"),
    "Connections": o([
        { json: "bim360_docs", js: "bim360_docs", typ: u(undefined, r("ConnectionsBim360Docs")) },
        { json: "bim360_field", js: "bim360_field", typ: u(undefined, r("Bim360Field")) },
        { json: "box", js: "box", typ: u(undefined, r("ConnectionsBox")) },
        { json: "google_drive", js: "google_drive", typ: u(undefined, r("ConnectionsGoogleDrive")) },
    ], "any"),
    "ConnectionsBim360Docs": o([
        { json: "cloud_storage", js: "cloud_storage", typ: u(undefined, r("CloudStorage")) },
    ], "any"),
    "CloudStorage": o([
        { json: "company_id", js: "company_id", typ: u(undefined, 3.14) },
        { json: "connected_by", js: "connected_by", typ: 0 },
        { json: "folder_id", js: "folder_id", typ: "" },
        { json: "folder_link", js: "folder_link", typ: "" },
        { json: "folder_name", js: "folder_name", typ: "" },
        { json: "options", js: "options", typ: r("Options") },
    ], "any"),
    "Options": o([
        { json: "can_select", js: "can_select", typ: u(undefined, true) },
        { json: "csv_data", js: "csv_data", typ: u(undefined, true) },
        { json: "daily_attachments", js: "daily_attachments", typ: u(undefined, true) },
        { json: "daily_reports", js: "daily_reports", typ: u(undefined, true) },
    ], "any"),
    "Bim360Field": o([
        { json: "connected_by", js: "connected_by", typ: u(undefined, 0) },
        { json: "ticket", js: "ticket", typ: u(undefined, "") },
    ], "any"),
    "ConnectionsBox": o([
        { json: "cloud_storage", js: "cloud_storage", typ: u(undefined, r("CloudStorage")) },
    ], "any"),
    "ConnectionsGoogleDrive": o([
        { json: "cloud_storage", js: "cloud_storage", typ: u(undefined, r("CloudStorage")) },
    ], "any"),
    "Notifications": o([
        { json: "email", js: "email", typ: true },
    ], "any"),
    "Tokens": o([
        { json: "android_push", js: "android_push", typ: a("") },
        { json: "api", js: "api", typ: u(undefined, r("API")) },
        { json: "bim360_docs", js: "bim360_docs", typ: u(undefined, r("TokensBim360Docs")) },
        { json: "box", js: "box", typ: u(undefined, r("TokensBox")) },
        { json: "google_drive", js: "google_drive", typ: u(undefined, r("TokensGoogleDrive")) },
        { json: "ios_push", js: "ios_push", typ: a("") },
    ], "any"),
    "API": o([
        { json: "access", js: "access", typ: a("") },
        { json: "active", js: "active", typ: true },
        { json: "key", js: "key", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], "any"),
    "TokensBim360Docs": o([
        { json: "access_token", js: "access_token", typ: "" },
        { json: "expires_at_ms", js: "expires_at_ms", typ: 0 },
        { json: "refresh_token", js: "refresh_token", typ: "" },
        { json: "account_id", js: "account_id", typ: u(undefined, "") },
        { json: "token_type", js: "token_type", typ: u(undefined, "") },
    ], "any"),
    "TokensBox": o([
        { json: "access_token", js: "access_token", typ: "" },
        { json: "expires_at_ms", js: "expires_at_ms", typ: 0 },
        { json: "refresh_token", js: "refresh_token", typ: "" },
        { json: "access_token_ttl_ms", js: "access_token_ttl_ms", typ: 0 },
        { json: "acquired_at_ms", js: "acquired_at_ms", typ: 0 },
        { json: "csrf", js: "csrf", typ: u(undefined, "") },
        { json: "redirect", js: "redirect", typ: u(undefined, "") },
    ], "any"),
    "TokensGoogleDrive": o([
        { json: "access_token", js: "access_token", typ: "" },
        { json: "expires_at_ms", js: "expires_at_ms", typ: 0 },
        { json: "refresh_token", js: "refresh_token", typ: "" },
    ], "any"),
};
export type UsersSchemaWithId = UsersSchema & RequiredId
