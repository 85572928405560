import BaseVueComponent from "common/BaseVueComponent"
import http, { isHttpError } from "common/http"
import attachmentsConfig from "components/attachments/attachments.config.json"
import AttachmentsDb from "components/attachments/attachments.db"
import { AttachmentsSchemaWithId } from "components/attachments/attachments.schema"
import { AttachmentsUtilsMixin, getAttachmentViewModel } from "components/attachments/attachments.utils"
import { companyName } from "components/companies/companies.utils"
import dailyConfig from "components/daily/daily.config.json"
import { applySearchFilter } from "components/filters/filters.utils"
import { getUserProjectCompany } from "components/project/project.utils"
import { hidePanelHeader, sortRecords } from "components/protest.utils.web"
import { userName, UserUtilsMixin } from "components/users/users.utils"
import groupBy from "lodash/groupBy"
import { Mixins, Prop, Watch } from "vue-property-decorator"

import { AttachmentViewerViewModel, AttachmentViewModel } from "./viewer"
export default class AttachmentViewerBase extends Mixins(BaseVueComponent, UserUtilsMixin, AttachmentsUtilsMixin) {
    @Prop(Array) public attachmentIds!: []
    public searchFilterShowsNoResults = false
    public noResultsFoundText = dailyConfig.noResultsFoundText
    public attachments: AttachmentViewModel[] = []
    @Prop(Number) public dailyId!: number
    @Prop({ default: false, type: Boolean }) public hideExpansionPanel!: boolean
    @Prop({ default: false, type: Boolean }) public locked!: boolean
    @Prop(Boolean) public disabled!: boolean
    @Prop({ default: false }) public disableExtendedViewer!: boolean
    @Prop(Boolean) public expandable!: boolean
    @Prop(Number) public openDefaultAttachment!: number
    @Prop(Boolean) public hideFilename!: boolean
    @Prop(Boolean) public isReadOnlySession!: boolean
    @Prop(Boolean) public showFilters!: boolean
    @Prop(String) public mode!: "list" | "thumbnails"
    @Prop({ default: true }) public thumbnailContain!: boolean
    @Prop({ default: "200" }) public thumbnailHeight!: number
    @Prop({ default: "200" }) public thumbnailWidth!: number
    @Prop() public attachment!: AttachmentsSchemaWithId | undefined | AttachmentViewerViewModel[]
    @Prop() public queryText!: string
    @Prop() public groupBy!: string
    @Prop() public sortBy!: string
    @Prop() public sortDesc!: boolean
    public attachmentsConfig = attachmentsConfig
    public pageCount = 0
    public currentPage = 1
    public page = 1
    public src = ""
    public thumbnail = ""
    public type = "image"
    public mimeType = ""
    public format!: string
    public enableViewer = false
    public currentAttachment: AttachmentsSchemaWithId | undefined
    public attachmentIdToDelete = -1
    public showMore: boolean[] = []
    public name!: string
    public showDeleteConfirmation = false
    public panelIndexToExpand: number[] = []
    public searchFilterKeys: searchFilterKey[] = attachmentsConfig.searchFilterKeys
    public currentExpandedKey = ""
    public documentId = -1
    public hideExtensionHeader = false
    public isFilterApplied = false

    public itemsPerPageOptionsGridView = [4, 8, 16, 32, 64]
    public itemsPerPageGridView = this.itemsPerPageOptionsGridView[1]
    public attachmentsToDisplay: Dictionary<AttachmentViewModel[]> = {}
    public $refs!: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attachmentsViewerImage: any
    }

    public hidePanelHeader(): string {
        return hidePanelHeader(this.hideExtensionHeader)
    }

    get isVideo(): boolean {
        return this.type === "video"
    }

    get isPdf(): boolean {
        return this.type === "pdf"
    }

    get isImage(): boolean {
        return this.type === "image"
    }

    public get imageHeight(): number | "auto" {
        if (Number.isNaN(this.thumbnailHeight)) return "auto"
        else return this.thumbnailHeight - 45
    }

    public async deleteAttachment(): Promise<void> {
        if (this.attachmentIdToDelete === -1) return

        const attachmentToDelete = this.attachments.find((attachment) => {
            return attachment.id === this.attachmentIdToDelete
        })

        if (!attachmentToDelete) return
        this.$emit("delete", attachmentToDelete.id)

        this.attachmentIdToDelete = -1
        return
    }

    public showMoreAttachment(index: number): void {
        this.showMore[index] = !this.showMore[index]
    }

    public showDeleteConfirmationDialog(id: number): void {
        this.attachmentIdToDelete = id
        this.showDeleteConfirmation = true
    }

    @Watch("attachmentIds", { immediate: true })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async onAttachmentsChange(newVal: any[]): Promise<void> {
        if (newVal.length === 0) {
            this.attachments = []
            return
        }

        let attachmentsResponse
        let openedAttachment = this.openDefaultAttachment

        if (this.isReadOnlySession) {
            // @ts-ignore getting the 0 the element passed as gallery UUID
            const uuid = this.attachmentIds[0]
            attachmentsResponse = await http.get<Array<AttachmentsSchemaWithId>>(process.env.FLYPAPER_API_URL + "/attachments/public/list/" + uuid)

            if (isHttpError(attachmentsResponse)) {
                this.snackEmitter.showHttpError(attachmentsResponse)
                return
            }

            openedAttachment = this.openDefaultAttachment
        } else {
            attachmentsResponse = await AttachmentsDb.readAll({
                filter: {
                    id: newVal.map((attachment) => attachment.id)
                }
            })
        }

        if (isHttpError(attachmentsResponse)) {
            this.snackEmitter.showHttpError(attachmentsResponse)
            return
        }

        this.attachments = attachmentsResponse.map((response) => {
            return getAttachmentViewModel(response, this.allCompaniesInHub, this.allUsers, this.project)
        })

        this.$emit("attachmentRecordsChanged", this.attachments)
        if (openedAttachment > -1) this.openAttachment(openedAttachment)

        this.attachments.forEach(() => {
            this.showMore.push(false)
        })

        this.filterAttachments()
    }

    @Watch("queryText", { immediate: false })
    @Watch("sortBy", { immediate: false })
    @Watch("groupBy", { immediate: false })
    @Watch("sortDesc", { immediate: false })
    public filterAttachments(): void {
        let localAttachments: AttachmentViewModel[] = []

        localAttachments = applySearchFilter(this.attachments, this.queryText, this.searchFilterKeys, "Attachment")
        localAttachments = this.sortAttachments(localAttachments)
        this.attachmentsToDisplay = this.groupAttachements(localAttachments)

        this.searchFilterShowsNoResults = this.queryText !== "" && localAttachments.length === 0 && this.attachments.length > 0 ? true : false
        if (Object.keys(this.attachmentsToDisplay).length === 1) this.panelIndexToExpand = [0]
    }

    /**
     * Sorts attachments
     *
     * @param attachments Attachments to sort
     * @returns Sorted attachment
     */
    public sortAttachments(attachments: AttachmentViewModel[]): AttachmentViewModel[] {
        const sortedRecords = sortRecords(attachments, this.sortBy, this.sortDesc)
        return sortedRecords
    }

    public groupAttachements(attachments: AttachmentViewModel[]): Dictionary<AttachmentViewModel[]> {
        switch (this.groupBy) {
            case "author":
                return groupBy(attachments, (x) => {
                    return userName(x.created_by, this.allUsers)
                })
            case "companyName":
                return groupBy(attachments, (x) => {
                    if (this.project) {
                        const userCompany = getUserProjectCompany(x.created_by, this.project)
                        if (userCompany && userCompany.company_id) return companyName(userCompany.company_id, this.allCompaniesInHub)
                    }
                })

            default:
                return { "All Attachments ": attachments }
        }
    }

    get id(): number {
        return this.currentAttachment ? this.currentAttachment.id : -1
    }

    public async openAttachment(id: number): Promise<void> {
        if (id < 0) return
        this.src = ""
        const attachmentToDisplay = this.attachments.find((attachment) => {
            return attachment.id === id
        })

        if (!attachmentToDisplay) return
        this.documentId = id
        this.currentAttachment = attachmentToDisplay

        const keys = Object.keys(this.attachmentsToDisplay)
        const key = keys.find((key) => {
            return this.attachmentsToDisplay[key].find((attachment) => {
                return attachment.id === this.currentAttachment?.id
            })
        })

        this.currentExpandedKey = key ? key : ""
        this.enableViewer = true

        // @ts-ignore getting the 0 the element passed as encrypted stream
        const uuid = this.attachmentIds[0]
        if (this.isReadOnlySession)
            this.thumbnail = process.env.FLYPAPER_API_URL + "/attachments/public/thumbnail/" + this.currentAttachment.id + "/" + uuid
        else this.thumbnail = process.env.FLYPAPER_API_URL + "/attachments/thumbnail/" + this.currentAttachment.id

        const type = this.currentAttachment.data.metadata?.mutate?.file_class || "image"

        if (type === "video") this.mimeType = "video/mp4"
        if (type === "audio") this.mimeType = "audio/mp3"
        const previewUrl = this.isReadOnlySession
            ? `${process.env.FLYPAPER_API_URL}/attachments/preview/${this.currentAttachment.id}?uuid=${uuid}`
            : `${process.env.FLYPAPER_API_URL}/attachments/preview/${this.currentAttachment.id}`

        const src = await http.get<string>(previewUrl)

        if (isHttpError(src)) return
        this.src = src
        if (type === "document" && src.includes("thumbnail")) {
            this.type = "image"
            return
        }

        this.type = type === "document" ? "pdf" : src.includes("thumbnail") ? "image" : type
    }
}
