import AttachmentsViewerDocument from "components/attachments/viewer/document/document.component.vue"
import AttachmentsViewerImage from "components/attachments/viewer/image/image.component.vue"
import AttachmentsViewerMedia from "components/attachments/viewer/media/media.component.vue"
import AttachmentsViewerBase from "components/attachments/viewer/viewer.client.base"
import { Component } from "vue-property-decorator"

import { AttachmentViewModel } from "./viewer"

@Component({
    components: {
        AttachmentsViewerDocument,
        AttachmentsViewerImage,
        AttachmentsViewerMedia
    }
})
export default class AttachmentsViewerWeb extends AttachmentsViewerBase {
    /**
     * Closes viewer
     */
    public closeViewer(): void {
        this.enableViewer = false
        this.currentAttachment = undefined
        this.documentId = -1
        if (this.$refs.attachmentsViewerImage) this.$refs.attachmentsViewerImage.closeFullScreen()
    }

    /**
     * Next attachment
     */
    public nextAttachment(): void {
        let currentIndex = 0
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.attachmentsToDisplay[this.currentExpandedKey]?.length > 0) {
            currentIndex = this.attachmentsToDisplay[this.currentExpandedKey].findIndex((attachment) => {
                return attachment.id === this.currentAttachment?.id
            })
        }

        let id = -1
        if (currentIndex === this.attachmentsToDisplay[this.currentExpandedKey].length - 1)
            id = this.attachmentsToDisplay[this.currentExpandedKey][0].id
        else id = this.attachmentsToDisplay[this.currentExpandedKey][currentIndex + 1].id

        this.openAttachment(id)
    }

    /**
     * Previous attachment
     */
    public previousAttachment(): void {
        let currentIndex = 0
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.attachmentsToDisplay[this.currentExpandedKey]?.length > 0) {
            currentIndex = this.attachmentsToDisplay[this.currentExpandedKey].findIndex((attachment) => {
                return attachment.id === this.currentAttachment?.id
            })
        }

        let id = -1
        if (currentIndex === 0)
            id = this.attachmentsToDisplay[this.currentExpandedKey][this.attachmentsToDisplay[this.currentExpandedKey].length - 1].id
        else id = this.attachmentsToDisplay[this.currentExpandedKey][currentIndex - 1].id

        this.openAttachment(id)
    }

    public attachmentInformation(attachment: AttachmentViewModel): string[] {
        return [
            "Name: " + attachment.filename,
            "Size: " + attachment.size,
            "Uploaded on: " + attachment.createdAt,
            "Uploaded by: " + attachment.author
        ]
    }

    public clearSearch(): void {
        this.$emit("clearSearchFilter")
    }

    public onFullScreen(): void {
        if (this.src === "") return
        this.$refs.attachmentsViewerImage.viewFullScreen()
    }
}
